import React, { useState } from "react"
import { PlayButton, VolumeControl } from 'react-soundplayer/components'
import { withCustomAudio } from 'react-soundplayer/addons'

import './player.css'

const Player = withCustomAudio(props => {

    const [volume, setVolume] = useState(1)
    const [playerClasses, setPlayerClasses] = useState('')

    const volumeChange = () => {
        if (volume === 1) {
            setVolume(0)
            setPlayerClasses(' is-muted')
            console.log(volume)
        } else {
            setVolume(1)
            setPlayerClasses('')
            console.log(volume)
        }
    }

    return (
        <div className={'player' + playerClasses}>
            <PlayButton 
                className="player__play player__btn"
            {...props} />
            {props.title &&
                <div className="live">
                    <span className="live__icon"></span><p className="live__text">Live Now - { props.title }</p>
                </div>
            }
            <VolumeControl
                className="player__vol"
                buttonClassName="player__btn"
                rangeClassName="player__vol-box"
                volume={0}
                onToggleMute={volumeChange}
            />
        </div>
    )
})

export default Player
