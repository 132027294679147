import React, { useEffect, useState } from "react"
import axios from 'axios'

import Player from './components/player/player'

import './App.css'

import instagramLogo from './assets/images/instagram.svg'
import soundcloudLogo from './assets/images/soundcloud.svg'
import mailLogo from './assets/images/mail.svg'
import facebookLogo from './assets/images/facebook.svg'

const App = () => {

    const [shows, setShows] = useState({ scEmbedLinks: [] })

    useEffect(() => {
        axios.get('https://cheesybean.co.uk/wordpress/alto/wp-json/acf/v3/posts', {
            headers: {
                Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IlYzV0d4cU5rcWlpNl9OM2l4Wk9xY3ciLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE1OTc2MDk5NzMsImV4cCI6MTYwMDIwMTk3MywiaXNzIjoiaHR0cHM6Ly9jbG91ZC5zcXVpZGV4LmlvL2lkZW50aXR5LXNlcnZlciIsImF1ZCI6InNxdWlkZXgtYXBpIiwiY2xpZW50X2lkIjoiYWx0bzpkZWZhdWx0Iiwic2NvcGUiOlsic3F1aWRleC1hcGkiXX0.gFtOult2QtquFZ2mQGgTj-55ofQUKj_cLibi92Vrz3cgGuXbwr-bf3cY67zmVhpPRerQ6MM2ROfQkpPynAv_vFUTGSNld3X6_mMqkqS8oqvjeSqzLm3SYWe0rV3fqBdw8ppjII6uGoYUDUrw40q_drKNzoPB8EPY-YEgWqFMY9Heve92YRX3eCbYD_f85VFJs6SzKp3WxSebJ7k9jVwmlSvkJ3lOC3GpkSfaWAcCBdtkTaQOU0GGjxJ-x6UfwsRyF2V72jz5uvLDk_qJabwDDe1Fa4NChrHvxjWXE4WmuLRt8hMj6Dnmyw6HO8HKvYKa34kv1_FvXoCj5r9l6dlGsA'
            }
        })
        .then(data => {
            console.log(data.data)
            const groups = data.data.reduce((groups, show) => {
                const date = show.acf.start_time.split(' ')[0]
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(show)
                return groups
            }, {})

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    shows: groups[date]
                }
            })

            const sortable = groupArrays.sort((a, b) => {
                const c = new Date(a.date)
                const d = new Date(b.date)
                window.console.log(a.date + ' ' + c)
                return c - d
            })

            window.console.log(sortable)

            setShows(groups)
        })
        .catch(function (error) {
            console.log(error)
        })
    }, [])
      
    return (
        <div className="app">
            <div className="wrap">
                <Player
                    streamUrl="http://stream.zeno.fm/gn6bn3tct7zuv"
                    preloadType="auto"
                />
                <div className="border-bottom">
                    <h2>Upcoming shows</h2>
                    <div className="shows-date">
                        {Object.keys(shows).map((item, i) => (
                            <div key={i} className="shows">
                                <p>{item}</p>
                                <div className="shows__row">
                                    {shows[item].map((show, i) => (
                                        <div className="flip-card" key={i}>
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img className="show__img" alt={show.acf?.title} src={show.acf?.image.url} />
                                                </div>
                                                <div className="flip-card-back">
                                                    <h2>
                                                        {show.acf?.title}
                                                    </h2>
                                                    <p>
                                                        {show.acf?.start_time} - {show.acf?.end_time}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="socials">
                    <a className="social-link" href="https://www.instagram.com/altoradio/" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ instagramLogo } alt="instagram" />
                    </a>
                    <a className="social-link" href="https://soundcloud.com/altoradio" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ soundcloudLogo } alt="soundcloud" />
                    </a>
                    <a className="social-link" href="https://facebook.com/altoradioo" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ facebookLogo } alt="instagram" />
                    </a>
                    <a className="social-link" href="mailto:hello@altoradio.live" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ mailLogo } alt="email" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default App
