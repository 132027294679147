import React, { useEffect, useState } from "react"
import Moment from 'react-moment';
import axios from 'axios'
import { Link } from "react-router-dom";

import Player from './components/player/player'

import './App.css'

import instagramLogo from './assets/images/instagram.svg'
import soundcloudLogo from './assets/images/soundcloud.svg'
import mailLogo from './assets/images/mail.svg'
import facebookLogo from './assets/images/facebook.svg'

const App = () => {

    const [scheduleContentFriday, setScheduleContentFriday] = useState()
    const [scheduleContentSaturday, setScheduleContentSaturday] = useState()
    const [scheduleContentSunday, setScheduleContentSunday] = useState()
    const [liveContent, setLiveContent] = useState()
    const [introContent, setIntroContent] = useState()
    const [recentShows, setRecentShows] = useState({ scEmbedLinks: [] })

    useEffect(() => {
        axios.get('https://cheesybean.co.uk/wordpress/alto/wp-json/acf/v3/pages/8', {
            headers: {
                Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IlYzV0d4cU5rcWlpNl9OM2l4Wk9xY3ciLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE1OTc2MDk5NzMsImV4cCI6MTYwMDIwMTk3MywiaXNzIjoiaHR0cHM6Ly9jbG91ZC5zcXVpZGV4LmlvL2lkZW50aXR5LXNlcnZlciIsImF1ZCI6InNxdWlkZXgtYXBpIiwiY2xpZW50X2lkIjoiYWx0bzpkZWZhdWx0Iiwic2NvcGUiOlsic3F1aWRleC1hcGkiXX0.gFtOult2QtquFZ2mQGgTj-55ofQUKj_cLibi92Vrz3cgGuXbwr-bf3cY67zmVhpPRerQ6MM2ROfQkpPynAv_vFUTGSNld3X6_mMqkqS8oqvjeSqzLm3SYWe0rV3fqBdw8ppjII6uGoYUDUrw40q_drKNzoPB8EPY-YEgWqFMY9Heve92YRX3eCbYD_f85VFJs6SzKp3WxSebJ7k9jVwmlSvkJ3lOC3GpkSfaWAcCBdtkTaQOU0GGjxJ-x6UfwsRyF2V72jz5uvLDk_qJabwDDe1Fa4NChrHvxjWXE4WmuLRt8hMj6Dnmyw6HO8HKvYKa34kv1_FvXoCj5r9l6dlGsA'
            }
        })
        .then(data => {
            setScheduleContentFriday(data.data.acf.friday_schedule)
            setScheduleContentSaturday(data.data.acf.saturday_schedule)
            setScheduleContentSunday(data.data.acf.sunday_schedule)
            setLiveContent(data.data.acf.live_show)
            setIntroContent(data.data.acf.intro_content)

            const newData = []
            newData.push(data.data.acf.recent_shows.show_one)
            newData.push(data.data.acf.recent_shows.show_two)
            newData.push(data.data.acf.recent_shows.show_three)
            newData.push(data.data.acf.recent_shows.show_four)
            setRecentShows(newData)
        })
        .catch(function (error) {
            console.log(error)
        })
    }, [])
      
    return (
        <div className="app">
            <div className="wrap">
                <div className="border-bottom">
                    <Player
                        title={ liveContent }
                        streamUrl="http://stream.zeno.fm/gn6bn3tct7zuv"
                        preloadType="auto"
                    />
                    <div className="top">
                        <div className="top__left">
                            <div className="logo">
                                <h1 className="logo__title">
                                    alto radio
                                </h1>
                            </div>
                            <div className="date">
                                <div>
                                    <Moment interval={1000} format="DD / MM / YYYY"></Moment>
                                </div>
                                <div>
                                    <Moment interval={1000} format="H:mm:ss"></Moment>
                                </div>
                            </div>
                        </div>
                        <div className="top__right" dangerouslySetInnerHTML={{ __html: introContent }} />
                    </div>                 
                </div>
                <div className="schedule border-bottom">
                    <div className="schedule__item" dangerouslySetInnerHTML={{ __html: scheduleContentFriday }} />
                    <div className="schedule__item" dangerouslySetInnerHTML={{ __html: scheduleContentSaturday }} />
                    <div className="schedule__item" dangerouslySetInnerHTML={{ __html: scheduleContentSunday }} />
                </div>
                <div className="soundcloud border-bottom">
                    <h2>recent shows</h2>

                    {Object.keys(recentShows).map((item, i) => (
                        <iframe 
                            key={i}
                            title={'Soundcloud iframe ' + i}
                            className="soundcloud__iframe soundcloud__iframe--left" 
                            allowtransparency="true" scrolling="no" frameBorder="no" 
                            src={recentShows[item]}>
                        </iframe>
                    ))}
                </div>
                <div className="socials">
                    <a className="social-link" href="https://www.instagram.com/altoradio/" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ instagramLogo } alt="instagram" />
                    </a>
                    <a className="social-link" href="https://soundcloud.com/altoradio" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ soundcloudLogo } alt="soundcloud" />
                    </a>
                    <a className="social-link" href="https://facebook.com/altoradioo" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ facebookLogo } alt="instagram" />
                    </a>
                    <a className="social-link" href="mailto:hello@altoradio.live" target="_blank" rel="noopener noreferrer">
                        <img className="social-link__icon" src={ mailLogo } alt="email" />
                    </a>
                    <Link className="test-link" to="/shows">Test</Link>
                </div>
            </div>
        </div>
    )
}

export default App
